<template>
	<div >
		<span>{{errorInfo}}</span>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				errorInfo: ''
			}
		},
		created: function () {
			var This = this;
            mgr.signinRedirectCallback().then(function () {
                mgr.getUser().then(function (user) {
					if (user) {
						localStorage.headertoken = user.access_token;
						localStorage.IsSSOUser = "true";
                        This.$AjaxRequest.AjaxSubmit("omsapi/auth/ssologin", "POST", {}, tokenResult => {
							if(tokenResult.IsSuccess){
                                localStorage.omstoken = tokenResult.Result.Token;
                                window.location.href = window.localSelfObj.websiteUrlPrefix + "/home";
							} else {
								This.errorInfo = tokenResult.OperationDesc;
							}
							
						}, null, true);
					}
				});
			});
		},
		methods: {
		}
	}
</script>
